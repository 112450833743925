import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "unnamed-docs-website"
    }}>{`Unnamed Docs website`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`unnamed`}</inlineCode>{` Docs website, built with `}<a parentName="p" {...{
        "href": "https://www.docz.site/"
      }}>{`Docz`}</a>{`, Gatsby and Deployed to `}<a parentName="p" {...{
        "href": "https://zeit.co/"
      }}>{`Zeit`}</a></p>
    <h2 {...{
      "id": "contributing"
    }}>{`Contributing`}</h2>
    <p>{`To be added`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      